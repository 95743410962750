import './sass/index.scss';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';

import axios from 'axios';
import App from './App.vue';
import { createApp } from 'vue';
import { router } from './router';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';
import { plugin, load } from './stores/plugins/persist';

// Setup Axios.
axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.withCredentials = true;

// Calculates the available screen space and makes it available to CSS as variable.
document.documentElement.style.setProperty(
    '--vh',
    window.innerHeight * 0.01 + 'px',
);
window.addEventListener('resize', () => {
    document.documentElement.style.setProperty(
        '--vh',
        window.innerHeight * 0.01 + 'px',
    );
});

// Bootstrap the application and load the Pinia state plugin.
const app = createApp(App).use(createPinia().use(plugin));

// Run our app after the persistent storage has populated the state.
load().then(() => {
    // Setup Sentry error tracking.
    if (process.env.NODE_ENV === 'production') {
        console.log('Sentry installed.');
        Sentry.init({
            app,
            dsn: 'https://1d788f284ac24b3c817d4c4d601cd966@o187899.ingest.sentry.io/6549953',
            release: __VERSION__,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation:
                        Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: [
                        'localhost',
                        'wonderwel.netlify.app',
                        /^\//,
                    ],
                }),
            ],
            tracesSampleRate: 0.25,
        });
    }

    app.use(router).mount('#app');
});
