<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue';

import Container from './Container.vue';
import TextButton from './TextButton.vue';

const { needRefresh, updateServiceWorker } = useRegisterSW({
  // Check for updates every hour.
  onRegistered(r) {
    r && setInterval(() => r.update(), 60 * 60 * 1000);
  },
});
</script>

<template>
  <div class="update-available" v-if="needRefresh">
    <Container class="w-full">
      <div class="flex items-center justify-between gap-2">
        <span> Er staat een update klaar! </span>
        <TextButton class="!h-10 !px-6 !text-xs" @click="updateServiceWorker">
          Verversen
        </TextButton>
      </div>
    </Container>
  </div>
</template>

<style lang="postcss" scoped>
.update-available {
  @apply fixed
    bottom-0
    left-0
    right-0

    flex
    h-24
    items-center
    justify-center

    bg-blue-300
    text-sm
    text-white;
}
</style>
