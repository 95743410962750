// @see https://dexie.org/docs/Tutorial/Vue
// @see https://dexie.org/docs/Version/Version.stores()#changing-the-schema

import Dexie from 'dexie';
import { importExploreCards, importLessonsCards } from './helpers';

const db = new Dexie('wonderwel');

/*
|------------------------------------------------------------------------------
| Database version 1.
|------------------------------------------------------------------------------
*/
db.version(4).stores({
    houses: '&id,deleted_at',
    resources: '&id,name,deleted_at',
    days: '&id,house_id,date,deleted_at',
    actions: '&id,house_id,name,deleted_at',
    things: '&id,house_id,name,deleted_at',
    facts: '&id,name,resource_id,deleted_at,lesson_type',
    questions: '&id,name,resource_id,deleted_at,lesson_type',
    exercises: '&id,name,resource_id,deleted_at,lesson_type',

    // Pivot tables.
    action_day: '&id,day_id,action_id,deleted_at',
    action_resource: '&id,action_id,resource_id,deleted_at',
    resource_thing: '&id,resource_id,thing_id,deleted_at',
    fact_house: '&id,house_id,fact_id,finished_at,deleted_at',
    house_question: '&id,house_id,question_id,finished_at,deleted_at',
    exercise_house: '&id,house_id,exercise_id,finished_at,deleted_at',
    question_house: '&id,house_id,question_id,finished_at,deleted_at',
    stats: '&id, type',
});

// Ensure that the default resources are available.
[
    {
        id: '0792a9a1-df25-46a6-9cad-1e9b3c6fdfe1',
        name: 'Drinkwater',
        type: 'water',
        description: 'Lekker helder water.',
        smallUsageDescription: 'een glas',
        mediumUsageDescription: 'een emmer',
        largeUsageDescription: 'een bad',
    },
    {
        id: 'b43bdcbd-bea0-48d3-a063-fb0165d6cf73',
        name: 'Aardgas',
        type: 'gas',
        description: 'Warm zonder aardgas.',
        smallUsageDescription: 'Een eitje koken',
        mediumUsageDescription: 'Een keertje douchen',
        largeUsageDescription: 'Een dag je huis verwarmen',
    },
    {
        id: 'e09f2948-20d1-4036-8825-0419a81c5121',
        name: 'Riool',
        type: 'sewer',
        description: 'Wat een uitvinding!',
        smallUsageDescription: 'een glas',
        mediumUsageDescription: 'een emmer',
        largeUsageDescription: 'een bad',
    },
    {
        id: '0700cd27-4834-4b38-a7db-f27478b4d24f',
        name: 'Afval',
        type: 'trash',
        description: 'Van afval tot grondstof.',
        smallUsageDescription: 'Biologisch afval',
        mediumUsageDescription: 'Plastic afval',
        largeUsageDescription: 'Chemisch afval',
    },
    {
        id: 'bef821af-5d0b-4a40-997b-8cb328699d57',
        name: 'Elektriciteit',
        type: 'electricity',
        description: 'Hoe wekken ze dat op?',
        smallUsageDescription: 'Een telefoon opladen',
        mediumUsageDescription: 'Een avond TV kijken',
        largeUsageDescription: 'Een wasdroger gebruiken',
    },
    {
        id: '0d2cbdc8-de87-4a0e-9756-d239e8d8707a',
        name: 'Internet',
        type: 'internet',
        description: 'Op de golven van het licht.',
        smallUsageDescription: 'Even iets opzoeken',
        mediumUsageDescription: 'Een uur videos kijken',
        largeUsageDescription: 'Een avond gamen',
    },
    {
        id: '6eed5c52-d2a8-4410-89e3-483a79fb5a6d',
        name: 'Voedsel',
        type: 'food',
        description: 'Vers van het land.',
        smallUsageDescription: 'Herkomst Nederland',
        mediumUsageDescription: 'Herkomst Europa',
        largeUsageDescription: 'Herkomst buiten Europa',
    },
].forEach((resource) => db.resources.put(resource));

import cards from './data/cards.json';
import lessons from './data/lessons.json';

export const cardVersionID = '8ebe27a0-599e-40bb-ac75-0cc41a245ede';

importExploreCards(cardVersionID, db, cards);
importLessonsCards('8ebe27a0-599e-40bb-ac75-0cc41a245edf', db, lessons);

export default db;
